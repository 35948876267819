/* eslint-disable @typescript-eslint/naming-convention */
import React from "react"
import Image from "next/image"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import Link from "@components/Link"
import Container from "@components/v2/Container"
import { Button } from "@components/v3/Button"
import ChangePercentageText from "@components/v3/ChangePercentageText"
import Table from "@components/v3/Table"
import { TableColumn } from "@components/v3/Table/Table"
import { COIN_TRADE_PAGE } from "@const/moengage-analytic-event"
import { css } from "@emotion/css"
import useMarketStocks from "@hooks/useMarketStock"
import Space from "@layouts/Space"
import { formatAbbreviatedNumber, formatNumber } from "@utils/currency"

const StyledContainer = tw(Container)`
    flex flex-col
    xl:py-10 py-8
    xl:gap-10 gap-6
`

const Title = tw.h1`
    xl:heading-4 heading-6
    text-bold dark:text-dark-bold
    text-center
`

const Desc = tw.span`
    xl:paragraph-2 paragraph-3
    text-main dark:text-dark-main
    text-center
    xl:max-w-[800px]
`

const TableWrapper = tw.div`
    rounded-3xl
    shadow-card
    flex flex-col items-center
    gap-4
    xl:p-8 p-4
`

const rowCss = css`
    height: 80px;

    ${tw`
        hover:!bg-transparent dark:hover:!bg-transparent
    `}

    td {
        @media screen and (min-width: 1280px) {
            padding-block: 16px;
            padding-inline: 24px;
        }
        ${tw`px-0`}

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
            padding-left: 0;
        }
    }
`

const headerCss = css`
    ${tw`text-additional dark:text-dark-additional`}
    * {
        ${tw`xl:!title-3 !title-5`}
    }
    th {
        @media screen and (min-width: 1280px) {
            padding-bottom: 15px;
            padding-inline: 24px;
        }
        ${tw`px-0`}

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
            padding-left: 0;
        }
    }
`

const Market = () => {
    const { t } = useTranslation()
    const { isLoading, stocks } = useMarketStocks({})
    const isDesktop = useMediaQuery({ minWidth: 1280 })
    const router = useRouter()

    const data = stocks
        ?.map((stock) => ({
            id: stock.id,
            stock_name: stock.name,
            stock_code: stock.code,
            logo: stock.logo,
            last_price: stock.price.close,
            "24h_change": stock.price.changePercentage.default,
            volume: stock.price.volume,
            market_cap: stock.information.marketCap
        }))
        .sort((a, b) => b.volume - a.volume)

    const columnsDesktop: TableColumn[] = [
        {
            title: t("home:explore.table.stock_name"),
            dataIndex: "stock_name",
            sort: false,
            width: 300,
            render: (value, record) => (
                <Space align='center' className='!gap-5 !w-full'>
                    <Image
                        priority
                        src={record?.logo}
                        alt={`${value}-logo`}
                        width={37}
                        height={37}
                        className='rounded-full'
                    />
                    <Space direction='vertical' className='!gap-[2px]'>
                        <span tw='title-3 text-bold dark:text-dark-bold'>{record?.stock_code}</span>

                        <span tw='text-additional dark:text-dark-additional title-4'>{value}</span>
                    </Space>
                </Space>
            )
        },
        {
            title: t("home:explore.table.last_price"),
            dataIndex: "last_price",
            align: "right",
            sort: false,
            width: 200,
            render: (value) => (
                <span tw='title-3 text-bold dark:text-dark-bold'>
                    ${formatNumber(value as number, { precision: 2 })}
                </span>
            )
        },
        {
            title: t("home:explore.table.change_24h"),
            dataIndex: "24h_change",
            align: "right",
            sort: false,
            width: 200,
            render: (value) => <ChangePercentageText className='!title-3' value={Number(value)} />
        },
        {
            title: t("home:explore.table.market_cap"),
            dataIndex: "market_cap",
            align: "right",
            sort: false,
            width: 200,
            render: (value) => (
                <span tw='title-3 text-bold dark:text-dark-bold'>
                    {value ? formatAbbreviatedNumber(value as number) : <>&#8212;</>}
                </span>
            )
        },
        {
            title: "",
            dataIndex: "stock_code",
            align: "center",
            width: 112,
            sort: false,
            render: (coinCode) => {
                const getUrl = () => {
                    return `/lightning/price/${coinCode}`
                }

                return (
                    <div className='flex items-center justify-end'>
                        <Link
                            href={getUrl()}
                            onClick={async () => {
                                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                                moengageAnalytic.track(COIN_TRADE_PAGE, {
                                    source: "Home"
                                })
                            }}
                        >
                            <Button variant='primary' size='lg' width={111} outline>
                                <span tw='button-1'>{t("common:buy")}</span>
                            </Button>
                        </Link>
                    </div>
                )
            }
        }
    ]
    const columnsMobile: TableColumn[] = [
        {
            title: t("home:explore.table.stock_name"),
            dataIndex: "stock_name",
            sort: false,
            render: (value, record) => (
                <Space align='center' className='!w-full '>
                    <div>
                        <Image
                            priority
                            src={record?.logo}
                            alt={`${value}-logo`}
                            width={28}
                            height={28}
                            className='rounded-full'
                        />
                    </div>
                    <Space direction='vertical' className='!gap-[2px]'>
                        <span tw='title-4 text-bold dark:text-dark-bold'>{record?.stock_code}</span>

                        <span tw='text-additional dark:text-dark-additional title-5 overflow-hidden text-ellipsis whitespace-nowrap w-16'>
                            {value}
                        </span>
                    </Space>
                </Space>
            )
        },
        {
            title: t("home:explore.table.last_price"),
            dataIndex: "last_price",
            align: "right",
            sort: false,
            width: 100,
            render: (value) => (
                <span tw='title-4 text-bold dark:text-dark-bold'>
                    ${formatNumber(value as number, { precision: 2 })}
                </span>
            )
        },
        {
            title: t("home:explore.table.change_24h"),
            dataIndex: "24h_change",
            align: "right",
            sort: false,
            width: 100,
            render: (value) => <ChangePercentageText className='!title-4' value={Number(value)} />
        }
    ]
    return (
        <StyledContainer>
            <Space direction='vertical' align='center'>
                <Title>{t("home:us_stock.market.title")}</Title>
                <Desc>{t("home:us_stock.market.desc")}</Desc>
            </Space>
            <TableWrapper>
                <Table
                    columns={isDesktop ? columnsDesktop : columnsMobile}
                    data={data}
                    loading={isLoading}
                    loadingRowCount={5}
                    maxRow={5}
                    headerClassName={headerCss}
                    rowClassName={() => rowCss}
                    onRow={(record) => ({
                        onClick: async () => {
                            if (!isDesktop) {
                                const { stock_code } = record

                                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                                moengageAnalytic.track(COIN_TRADE_PAGE, {
                                    source: "Home"
                                })
                                router.push(`/lightning/price/${stock_code}`)
                            }
                        }
                    })}
                    noHover
                />
                <Button block={!isDesktop} size={isDesktop ? "xl" : "lg"} onClick={() => router.push("/markets")}>
                    <span className='button-1'>{t("home:us_stock.market.explore")}</span>
                </Button>
            </TableWrapper>
        </StyledContainer>
    )
}

export default Market
