import { useCallback, useEffect, useRef } from "react"
import { useRouter } from "next/router"
import isEqual from "lodash.isequal"
import throttle from "lodash.throttle"

import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { fetchStocks as fetchStocksActions, fetchStocksAsync } from "@redux/market-stock/actions"
import { updateMarketStocks } from "@redux/market-stock/slicer"

const throttleRefetch = throttle((action: Function) => action(), 60000, { trailing: false })

interface Filter {
    search: string
}

export interface MarketStockArgs {
    filter?: Filter
}
const useMarketStocks = (args?: MarketStockArgs) => {
    const { stocks } = useAppSelector((state) => state.marketStock)
    const dispatch = useAppDispatch()
    const router = useRouter()
    const onCloseRef = useRef<string>()

    useEffect(() => {
        dispatch(fetchStocksActions())
    }, [dispatch, router.asPath])

    const isLoading = !stocks

    const desiredCoinCodes = [
        "BAC",
        "C",
        "JPM",
        "MA",
        "V",
        "BCS",
        "AMZN",
        "GOOG",
        "GOOGL",
        "AAPL",
        "HOOD",
        "MSFT",
        "TSLA",
        "NFLX",
        "ADBE",
        "IBM",
        "ACN",
        "AMD",
        "NVDA",
        "T",
        "ABNB"
    ]
    const data: StockModel[] | null = !isLoading
        ? stocks?.filter((stock) => {
              if (!stock) return false
              const search = args?.filter?.search.toLowerCase()

              if (search) {
                  const name = stock.name.toLowerCase()
                  const code = stock.code.toLowerCase()

                  return (name.includes(search) || code.includes(search)) && desiredCoinCodes.includes(code)
              }

              return desiredCoinCodes.includes(stock.code)
          })
        : null

    const handleUpdateMarketStocks = useCallback(
        (newData: MarketStockSocketPayload) => {
            dispatch(updateMarketStocks(newData))
        },

        [dispatch]
    )

    useEffect(() => {
        ;(async () => {
            const { default: Socket } = await import("@lib/socket")
            Socket.subscribe("us-stock-market", handleUpdateMarketStocks)
        })()

        return () => {
            ;(async () => {
                const { default: Socket } = await import("@lib/socket")
                Socket.unsubscribe("us-stock-market")
            })()
        }
    }, [handleUpdateMarketStocks])

    useEffect(() => {
        ;(async () => {
            const { default: Socket } = await import("@lib/socket")
            onCloseRef.current = Socket.socket.onClose(() => {
                throttleRefetch(() => {
                    dispatch(fetchStocksAsync())
                })
            })
        })()

        return () => {
            ;(async () => {
                const { default: Socket } = await import("@lib/socket")
                if (onCloseRef.current) Socket.socket.off([onCloseRef.current])
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        stocks: data,
        isLoading
    }
}

const useStock = () => {
    const router = useRouter()

    const dispatch = useAppDispatch()

    const stocks: MarketStockModel[] | null = useAppSelector(
        (state) =>
            state.marketStock?.stocks?.map((stock) => ({
                id: stock.id,
                code: stock.code,
                name: stock.name,
                logo: stock.logo,
                symbol: stock.symbol,
                sorting: stock.sorting,
                minimumTradeQuote: stock.minimumTradeQuote,
                information: {
                    maxSupply: stock.information.maxSupply,
                    circulatingSupply: stock.information.circulatingSupply,
                    totalSupply: stock.information.totalSupply,
                    marketCap: stock.information.marketCap,
                    globalVolume: stock.information.globalVolume
                },
                quoteFee: stock.quoteFee,
                decimals: {
                    quote: stock.decimals.quote,
                    shares: stock.decimals.shares
                },
                estimationSlippage: stock.estimationSlippage
            })) || null,
        isEqual
    )

    const isLoading = !stocks

    useEffect(() => {
        dispatch(fetchStocksActions())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath])

    return { stocks, isLoading }
}

export const useMarketStockByCode = (code: string) => {
    const { stocks, isLoading } = useStock()

    const stock = stocks?.find((item) => item.code === code)

    return { stock, isLoading }
}

export default useMarketStocks
